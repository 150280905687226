import { api } from "./api";

  
export const userLogin = async (data) => {
  const response = await api.post("auth/login", {
    email: data.email,
    password: data.password
  });
  return response;
};

export const createUser = async (data, phone, countryCode) => {
  const response = await api.post("user/register", {
    firstname: data.firstName,
    lastname : data.lastName,
    email: data.email,
    password: data.password,
    mobile: phone.slice(countryCode?.length),
    country_code: countryCode,
    address: data.address,
    referedBy: data.referral,
  });
  return response;
};


export const forgetPassword = async (data) => {
  const response = await api.post("auth/forgetPassword", {
    email: data.email, 
  });
  return response;
};


export const changePassword = async (data) => {
  const response = await api.post("auth/changePassword", {
    verify_id: data.verify_id, 
    password: data.password,  
  });
  return response;
};

