import { api } from "./api";
 

  export const viewUserTransaction = async (data) => {
    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post("transaction/viewUserTransaction", {
        id: data.id, 
    });
    return response;
  };

  
  export const viewAllUserTransactions = async (data) => { 
    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
    try { 
     const response = await api.post('transaction/viewAllUserTransactions', { 
      page: data.page, 
      fromDate: data.fromDate,
      toDate: data.toDate,
     }); 
     return response;

   } catch (err) {
      console.error(err.message);
   }
   
 };

 export const viewAllTransactions = async (data) => { 
 
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
  try { 
   const response = await api.post('transaction/viewAllTransactions', { 
    page: data.page,
    referredBy: data.referredBy,
    fromDate: data.fromDate,
    toDate: data.toDate,
   }); 
   return response;

 } catch (err) {
    console.error(err.message);
 }
 
}; 
 
 
 export const addUserTransaction = async (data) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
  const response = await api.post("transaction/addUserTransaction", { 
      plan_id: data.plan_id, 
  });
  return response;
}; 


 export const getPaymentSuccess = async (data) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
  const response = await api.post("transaction/getPaymentSuccess", data);
  return response;
}; 

  


export const getReferalTransactions = async (data) => { 
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
  try { 
   const response = await api.post('transaction/getReferalTransactions', { 
    page: data.page, 
    fromDate: data.fromDate,
    toDate: data.toDate,
   }); 
   return response;

 } catch (err) {
    console.error(err.message);
 }
 
}; 