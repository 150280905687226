 
import { api } from "./api";
 

  export const getAllPlans = async (data) => {
    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
     try { 
      const response = await api.post('plans/getAllPlans', {}); 
      return response;

    } catch (err) {
       console.error(err.message);
    }
   
  };

 