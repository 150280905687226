import { api } from "./api";
 
 
export const addGroupContact = async (data, value) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post("groupContact/addGroupContact", {
        group_id: data.group_id,
        contacts: data.contacts, 
    });
    return response;
  };
 

export const removeGroupContact = async (data) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post("groupContact/removeGroupContact", {
        group_id: data.group_id,
        contact_id:data.contact_id, 

    });
    return response;
  };



  export const viewAllContactsGroup = async (data) => {
    api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post("groupContact/viewAllContactsGroup", {
        contact_id: data.id,
    });
    return response;
   
  };

 
  export const viewAllGroupContacts = async (data) => {
    api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post("groupContact/viewAllGroupContacts", {
        group_id: data.id,
    });
    return response;
   
  };


  export const viewAllContact = async (data) => {
    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post("groupContact/viewAllContact", {
        group_id: data.id,
    });
    return response;
   
  };

  
export const uploadBulkContacts = async (data) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
  const response = await api.post("groupContact/uploadBulkContacts", {
    group_id: data.group_id, 
    data: data.data, 
  });
  return response;
};
