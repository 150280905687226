import React from 'react'
import Chat from '../chat/Chat'
 
function Message(props) {
 

  return (
    <div>
      
        <Chat setValue={props.setValue} />
      

    </div>
  )
}

export default Message